import React, { FC, memo } from "react";
import styled from "styled-components";
import { Github, ExternalLink, Gitlab } from "lucide-react";
import { Colors } from "../../styles/Colors";
import { ProjectProps } from "./ProjectsData";

const ProjectCard = styled.div`
  background-color: ${Colors.DarkCharcoal};
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease;
  box-shadow: 0px 0px 5px ${Colors.WhiteCloud};

  &:hover {
    transform: translateY(-5px);
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const ProjectContent = styled.div`
  padding: 1.5rem;
`;

const ProjectTitle = styled.h3`
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: ${Colors.White};
`;

const ProjectDescription = styled.p`
  font-size: 0.9rem;
  color: ${Colors.LightGray};
  margin-bottom: 1rem;
`;

const TechStack = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const TechTag = styled.span`
  background-color: ${Colors.WhiteCloud};
  opacity: 0.8;
  color: ${Colors.DarkGray};
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const ViewProject = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.DeepSkyBlue};
  color: ${Colors.White};
  padding: 0.5rem 1rem;
  border: 2px solid transparent;
  border-radius: 4px;
  text-decoration: none;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;

  &:hover {
    border: 2px solid ${Colors.ReactBlue};
  }

  &:active {
    transform: scale(0.95);
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const ProjectContainer: FC<ProjectProps> = memo(
  ({
    id,
    title,
    description,
    imageUrl,
    technologies,
    sourceCodeUrl,
    liveAppUrl,
    isFullStack,
    frontendUrl,
    backendUrl,
  }) => {
    return (
      <ProjectCard key={id}>
        <ProjectImage src={imageUrl} alt={title} />
        <ProjectContent>
          <ProjectTitle>{title}</ProjectTitle>
          <ProjectDescription>{description}</ProjectDescription>
          <TechStack>
            {technologies.map((tech, index) => (
              <TechTag key={index}>{tech}</TechTag>
            ))}
          </TechStack>
          <ButtonContainer>
            {sourceCodeUrl && !isFullStack && (
              <ViewProject
                href={sourceCodeUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Github size={16} />
                Source Code
              </ViewProject>
            )}
            {liveAppUrl && (
              <ViewProject
                href={liveAppUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ExternalLink size={16} />
                Live App
              </ViewProject>
            )}

            {isFullStack && frontendUrl && backendUrl && (
              <>
                <ViewProject
                  href={frontendUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Gitlab size={16} />
                  Frontend
                </ViewProject>
                <ViewProject
                  href={backendUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Gitlab size={16} />
                  Backend
                </ViewProject>
              </>
            )}
          </ButtonContainer>
        </ProjectContent>
      </ProjectCard>
    );
  }
);
export default ProjectContainer;
