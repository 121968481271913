import React, { FC } from "react";

import styled from "styled-components";
import { Colors } from "../styles/Colors";

const Nav = styled.nav`
  top: 0;
  position: sticky;
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  background-color: ${Colors.DarkReact};
  opacity: 0.99;
  padding-right: 50px;
  gap: 15px;
  height: 3.5rem;

  @media (max-width: 768px) {
    height: auto;
    font-size: 0.8rem;
    justify-content: center;
    padding: 10px 0;
  }
`;

const Link = styled.a`
  font-size: 1.3em;
  font-weight: bold;
  margin-right: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: white;
  text-decoration: none;
  &:hover {
    color: ${Colors.ReactBlue};
    text-decoration: none;
  }

  @media (max-width: 768px) {
    margin-right: 0;
  }
`;

const NavigationBar: FC = () => {
  return (
    <Nav data-testid="navigation-bar">
      <Link href="#home">Home</Link>
      <Link href="#about">About</Link>
      <Link href="#projects">Projects</Link>
      <Link href="#contact">Contact</Link>
    </Nav>
  );
};

export default NavigationBar;
