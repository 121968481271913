import { TechItemProps } from "./TechIcon";
import { Mail, Github, Linkedin, Gitlab } from "lucide-react";

export const techStack: TechItemProps[] = [
  { name: "TypeScript", icon: "siTypescript" },
  { name: "React", icon: "siReact" },
  { name: "JavaScript", icon: "siJavascript" },
  { name: "Node.js", icon: "siNodedotjs" },
  { name: "Python", icon: "siPython" },
  { name: "Django", icon: "siDjango" },
  { name: "PostgreSQL", icon: "siPostgresql" },
  { name: "Git", icon: "siGit" },
  { name: "Docker", icon: "siDocker" },
  { name: "Jira", icon: "siJira" },
];

export const ProfileData = {
  name: "Carlos Reyes Marquez",
  title: "University of Sherbrooke 🎓",
  description: `Hello! I'm a Venezuelan-Canadian Software Developer with a strong passion for DevOps and automation.
  I love finding ways to automate processes and make things more efficient.
  My focus is on building web applications and improving workflows to deliver real and impactful results.
  I also believe in writing clear, detailed documentation to keep projects easy to maintain and encourage smooth collaboration.
  If you'd like to reach out or discuss anything tech-related — I’m always up for a great conversation!`,
  imageSrc: "images/profile-picture.jpeg",
};

export const socialLinks = [
  { href: "mailto:creyesmarquez@gmail.com", icon: Mail, label: "Email" },
  {
    href: "https://www.linkedin.com/in/creyesmarquez/",
    icon: Linkedin,
    label: "LinkedIn",
  },
  { href: "https://github.com/creyesmarquez", icon: Github, label: "GitHub" },
  { href: "https://gitlab.com/creyesmarquez", icon: Gitlab, label: "GitLab" },
];
