import React, { FC } from "react";
import { Section } from "../components/Section";
import ContactForm from "../components/ContactForm";

const Contact: FC = () => (
  <Section id="contact" title="Contact">
    <ContactForm />
  </Section>
);
export default Contact;
