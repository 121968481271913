export type ProjectProps = {
  id: number;
  title: string;
  description: string;
  isFullStack?: boolean;
  imageUrl: string;
  technologies: string[];
  sourceCodeUrl?: string;
  liveAppUrl?: string;
  frontendUrl?: string;
  backendUrl?: string;
};

export const projects: ProjectProps[] = [
  {
    id: 1,
    title: "Portfolio Website",
    description:
      "My portfolio website using a full-stack solution. Built with React and Django, this site highlights clean and responsive design.",
    isFullStack: true,
    imageUrl: "images/projects/portfolio.png",
    technologies: ["React", "Django", "GitLab CI/CD"],
    sourceCodeUrl: "https://gitlab.com/fullstack-web-portfolio",
    frontendUrl: "https://gitlab.com/creyesmarquez/portfolio-website",
    backendUrl: "https://gitlab.com/creyesmarquez/portfolio-server",
  },
  {
    id: 2,
    title: "Useful Scripts",
    description:
      "A collection of practical scripts designed to streamline various tasks and automate repetitive processes. Each script is crafted to serve a specific purpose.",
    imageUrl: "images/projects/useful-scripts.jpg",
    technologies: ["Python", "Beancount", "CSV"],
    sourceCodeUrl: "https://github.com/creyesmarquez/useful-scripts",
  },
  {
    id: 3,
    title: "CTF Sherbrooke",
    description:
      "A website for my local church built with Wordpress. This site provides information about the church, its services, and upcoming events.",
    imageUrl: "images/projects/ctf.png",
    technologies: ["Wordpress"],
    liveAppUrl: "https://ctfsherbrooke.ca/",
  },
];
