import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useState,
} from "react";
import styled from "styled-components";
import { Colors } from "../styles/Colors";
import axios from "axios";

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

const FormWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  background-color: ${Colors.DarkCharcoal};
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ContactDescription = styled.p`
  color: ${Colors.WhiteCloud};
  margin-bottom: 1.5rem;
  text-align: center;
  line-height: 1.5;
`;

const Label = styled.label`
  color: ${Colors.WhiteCloud};
`;

const Input = styled.input`
  color: ${Colors.White};
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid ${Colors.DeepSkyBlue};
  background-color: ${Colors.DarkGray};
  font-size: 1rem;

  &:focus {
    border: 1px solid ${Colors.ReactBlue};
    outline: none;
  }
`;

const TextArea = styled.textarea`
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid ${Colors.DeepSkyBlue};
  background-color: ${Colors.DarkGray};
  color: ${Colors.White};
  font-size: 1rem;
  min-height: 120px;
  resize: vertical;

  &:focus {
    border: 1px solid ${Colors.ReactBlue};
    outline: none;
  }
`;

const SubmitButton = styled.button`
  width: fit-content;
  padding: 0.75rem;
  background-color: ${Colors.DeepSkyBlue};
  color: ${Colors.White};
  border: 2px solid transparent;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    border: 2px solid ${Colors.ReactBlue};
  }

  &:active {
    transform: scale(0.95);
  }
`;

const ConfirmationMessage = styled.div`
  color: white;
  font-size: 1.2rem;
  text-align: center;
  margin: 50px;
  padding-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

type FormDataType = {
  name: string;
  email: string;
  message: string;
};

const ContactForm: FC = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState<FormDataType>({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    },
    [formData]
  );
  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_CONTACT_ENDPOINT}`,
          formData
        );

        if (response.status === 201) {
          setFormData({
            name: "",
            email: "",
            message: "",
          });
          setIsSubmitted(true);
        }
      } catch (error) {
        alert("An error occurred. Please try again.");
      }
    },
    [formData]
  );

  return (
    <>
      {isSubmitted ? (
        <ConfirmationMessage>
          Thank you for your message! I'll get back to you soon.
        </ConfirmationMessage>
      ) : (
        <FormContainer>
          <FormWrapper>
            <Form onSubmit={handleSubmit} method="post" autoComplete="off">
              <ContactDescription>
                Have a question or want to work together ?<br></br>
                Please don't hesitate to reach out. Leave your details and I'll
                get back to you as soon as possible.
              </ContactDescription>
              <Label htmlFor="name">Name</Label>
              <Input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <Label htmlFor="email">Email</Label>
              <Input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <Label htmlFor="message">Message</Label>
              <TextArea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows={10}
                required
              />
              <SubmitButton type="submit">Submit</SubmitButton>
            </Form>
          </FormWrapper>
        </FormContainer>
      )}
    </>
  );
};
export default ContactForm;
