export const Colors = {
  Black: "#000",
  White: "#fff",
  WhiteCloud: "#f0f0f0",
  DarkGray: "#333",
  LightGray: "#ccc",
  LightDarkDray: "#cdd9e526",
  DeepSkyBlue: "#0077b5",
  DarkCharcoal: "#282828",
  ReactBlue: "#61dafb",
  DarkReact: "#282c34",
  LightBlue: "#a0d8f1",
};
