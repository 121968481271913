import React from "react";
import styled from "styled-components";
import { Colors } from "../../styles/Colors";
import * as SimpleIcons from "simple-icons";

const TechTag = styled.span`
  background-color: ${Colors.WhiteCloud};
  opacity: 0.8;
  color: ${Colors.DarkGray};
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
  align-items: center;

  svg {
    vertical-align: middle;
    margin-right: 0.25rem;
    width: 16px;
    height: 16px;
  }
`;

export type TechItemProps = {
  name: string;
  icon: string;
};

export const TechIcon = ({ name, icon }: TechItemProps) => {
  const SimpleIcon = (SimpleIcons as any)[icon];
  return (
    <TechTag>
      <svg
        role="img"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={`#${SimpleIcon.hex}`}
      >
        <title>{name}</title>
        <path d={SimpleIcon.path} />
      </svg>
      {name}
    </TechTag>
  );
};
