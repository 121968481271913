import React, { FC } from "react";
import styled from "styled-components";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Colors } from "../styles/Colors";

const FooterContainer = styled.footer`
  background-color: ${Colors.DarkReact};
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const SocialMediaLinks = styled.div`
  margin: 10px 0;
`;

const SocialIcon = styled.a`
  color: white;
  font-size: 2em;
  margin: 0 15px;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: ${Colors.ReactBlue};
  }
`;

const ScrollToTopButton = styled.button`
  background-color: ${Colors.DeepSkyBlue};
  color: white;
  border: 2px solid transparent;
  padding: 8px 15px;
  border-radius: 5px;
  font-size: 1em;
  margin-bottom: 20px;

  cursor: pointer;
  transition: background-color 0.3s;
  position: relative;

  &:hover {
    border: 2px solid ${Colors.ReactBlue};
  }

  &:active {
    transform: scale(0.95);
  }
`;

const handleScrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const Footer: FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterContainer data-testid="footer">
      <ScrollToTopButton onClick={handleScrollToTop}>
        <i className="fas fa-arrow-up"></i>
      </ScrollToTopButton>
      <SocialMediaLinks>
        <SocialIcon
          href="mailto:creyesmarquez@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fas fa-envelope"></i>
        </SocialIcon>
        <SocialIcon
          href="https://www.linkedin.com/in/creyesmarquez/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-linkedin"></i>
        </SocialIcon>
        <SocialIcon
          href="https://github.com/creyesmarquez"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-github"></i>
        </SocialIcon>
        <SocialIcon
          href="https://gitlab.com/creyesmarquez"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-gitlab"></i>
        </SocialIcon>
      </SocialMediaLinks>
      <p>© {currentYear} Carlos Reyes Marquez. All rights reserved.</p>
    </FooterContainer>
  );
};
export default Footer;
