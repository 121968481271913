import styled from "styled-components";
import React, { FC, ReactNode } from "react";
import { Colors } from "../styles/Colors";
import useIntersectionObserver from "../hooks/useIntersectionObserver";

const SectionContainer = styled.section`
  background-color: ${Colors.Black};
`;

const SectionTitle = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isVisible",
})<{ isVisible: boolean }>`
  font-size: 3rem;
  font-weight: bold;
  background: linear-gradient(90deg, ${Colors.ReactBlue}, ${Colors.LightBlue});
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding-top: 100px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible }) =>
    isVisible ? "translateY(0)" : "translateY(20px)"};
  transition: opacity 1s ease, transform 1s ease;

  @media (max-width: 768px) {
    font-size: 2.5rem;
    padding-top: 80px;
  }
`;

const SectionContent = styled.div`
  color: white;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

type Props = {
  id: string;
  title?: string;
  children: ReactNode;
};

export const Section: FC<Props> = ({ id, title, children }) => {
  const testId = title?.toLowerCase() + "-section";

  const { ref, isVisible } = useIntersectionObserver();
  return (
    <SectionContainer id={id} data-testid={testId}>
      <SectionTitle ref={ref} isVisible={isVisible}>
        {title}
      </SectionTitle>
      <SectionContent>{children}</SectionContent>
    </SectionContainer>
  );
};
