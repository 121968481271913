import React, { FC } from "react";
import { Section } from "../components/Section";
import { styled } from "styled-components";
import { SquareCode } from "lucide-react";
import { Colors } from "../styles/Colors";
import { TechIcon } from "../components/Profile/TechIcon";
import {
  techStack,
  ProfileData,
  socialLinks,
} from "../components/Profile/ProfileData";

const ProfileContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (min-width: 768px) {
    flex-direction: row;
    text-align: left;
    align-items: flex-start;
  }
`;

const ProfileImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    margin-right: 3rem;
    margin-bottom: 0;
  }
`;

const ProfileContent = styled.div`
  flex: 1;
`;

const Name = styled.h1`
  font-size: 2.5rem;
  color: ${Colors.White};
  margin-bottom: 0.5rem;
`;

const Title = styled.h2`
  font-size: 1.25rem;
  color: ${Colors.ReactBlue};
  margin-bottom: 1rem;
`;

const Description = styled.p`
  font-size: 1rem;
  color: ${Colors.WhiteCloud};
  line-height: 1.6;
  margin-bottom: 1.5rem;
`;

const TechStackContainer = styled.div`
  margin-bottom: 1.5rem;
`;

const TechStackTitle = styled.h3`
  font-size: 1.1rem;
  color: ${Colors.WhiteCloud};
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
`;

const TechStack = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;

  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`;

const SocialLink = styled.a`
  color: ${Colors.ReactBlue};
  transition: color 0.3s ease;

  &:hover {
    color: ${Colors.DeepSkyBlue};
  }
`;

const About: FC = () => {
  return (
    <Section data-testid="about-section" id="about" title="About me">
      <ProfileContainer>
        <ProfileImage src={ProfileData.imageSrc} title={ProfileData.title} />
        <ProfileContent>
          <Name>{ProfileData.name}</Name>
          <Title>{ProfileData.title}</Title>
          <Description>{ProfileData.description}</Description>
          <TechStackContainer>
            <TechStackTitle>
              <SquareCode size={20} />
              Technologies
            </TechStackTitle>
            <TechStack>
              {techStack.map((tech) => (
                <TechIcon key={tech.name} name={tech.name} icon={tech.icon} />
              ))}
            </TechStack>
          </TechStackContainer>
          <SocialLinks>
            {socialLinks.map((social) => (
              <SocialLink
                key={social.label}
                href={social.href}
                target="_blank"
                rel="noreferrer"
                aria-label={social.label}
              >
                <social.icon size={24} />
              </SocialLink>
            ))}
          </SocialLinks>
        </ProfileContent>
      </ProfileContainer>
    </Section>
  );
};
export default About;
