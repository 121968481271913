import { createGlobalStyle } from "styled-components";
import { Colors } from "./Colors";

export const GlobalStyles = createGlobalStyle`

  html {
    scroll-behavior: smooth;
  }
  body {
    font-family: montserrat , sans-serif;
    margin: 0;
    padding: 0;
  }
  h1 {
    font-size: 2rem;
    color: ${Colors.DarkGray};
  }
  h2 {
    font-size: 1.5rem;
    color: ${Colors.DarkGray};
  }
  p {
    font-size: 1rem;
  }
  section {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: #fff;
  }
  nav a {
    text-decoration: none;
  }
  nav a:hover {
    text-decoration: underline;
  }
  nav ul {
    display: flex;
    list-style: none;
  }
  nav ul li {
    margin-right: 10px;
  }
  nav ul li:last-child {
    margin-right: 0;
  }
  nav ul li a {
    color: #fff;
    text-decoration: none;
  }
  nav ul li a:hover {
    text-decoration: underline;
  }
  textarea {
  font-family: montserrat , sans-serif;
  }
  input {
  font-family: montserrat , sans-serif;
  }
`;
