import React, { FC } from "react";
import styled from "styled-components";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Colors } from "../styles/Colors";
import ParticlesBackground from "../components/ParticlesBackground";
import { Typewriter } from "react-simple-typewriter";

const HomeContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
  overflow: hidden;
`;

const ParticlesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  canvas {
    aspect-ratio: 1 / 1.2;
  }

  @media (max-width: 768px) {
    canvas {
      aspect-ratio: 1 / 3;
    }
`;

const WelcomeName = styled.div`
  font-size: 2em;
  z-index: 1;
`;

const NameHighlight = styled.span`
  color: ${Colors.ReactBlue};
`;

const JobTitle = styled.div`
  font-size: 1.5em;
  z-index: 1;
`;

const ViewWorkButton = styled.button`
  background-color: ${Colors.DarkReact};
  color: ${Colors.White};
  border: 2px solid transparent;
  padding: 8px 25px;
  border-radius: 5px;
  font-size: 1em;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative;

  &:hover {
    border: 2px solid ${Colors.ReactBlue};
  }

  &:active {
    transform: scale(0.95);
  }
`;

const ViewWorkIcon = styled.i`
  margin-left: 5px;
`;

const handleViewWork = () => {
  const projects = document.getElementById("projects");
  projects?.scrollIntoView({ behavior: "smooth" });
};

const Home: FC = () => (
  <HomeContainer id="home" data-testid="home-section">
    <ParticlesWrapper>
      <ParticlesBackground />
    </ParticlesWrapper>
    <WelcomeName>
      Hello, I'm <NameHighlight>Carlos</NameHighlight>,
    </WelcomeName>
    <JobTitle>
      a
      <Typewriter
        words={[
          " Fullstack Developer.",
          " DevOps Enthusiast.",
          "n Arsenal Fan.",
          " Web Developer.",
          " Business Visionary.",
        ]}
        loop={0}
        cursor
        cursorStyle="_"
        typeSpeed={70}
        deleteSpeed={50}
        delaySpeed={2000}
      />
    </JobTitle>
    <ViewWorkButton onClick={handleViewWork}>
      View my work
      <ViewWorkIcon>
        <i className="fas fa-arrow-down"></i>
      </ViewWorkIcon>
    </ViewWorkButton>
  </HomeContainer>
);

export default Home;
