import { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import { ISourceOptions } from "@tsparticles/engine/types/Types/ISourceOptions";
import { Colors } from "../styles/Colors";

const ParticlesBackground = () => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const options: ISourceOptions = useMemo(
    () => ({
      fullScreen: {
        enable: false,
      },
      background: {
        color: {
          value: Colors.Black,
        },
      },
      fpsLimit: 60,
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: "push", // Add particles when clicked
          },
          onHover: {
            enable: true,
            mode: "repulse", // Repel particles when hovering
          },
        },
        modes: {
          push: {
            quantity: 4, // Add 4 particles on click
          },
          repulse: {
            distance: 100,
            duration: 0.4, // Repel effect duration
          },
        },
      },
      particles: {
        color: {
          value: Colors.DarkReact,
        },
        links: {
          color: Colors.ReactBlue,
          distance: 150,
          enable: true, // Enable linking between particles
          opacity: 0.5,
          width: 1,
        },
        move: {
          enable: true, // Allow movement
          outModes: "out",
          random: false,
          speed: 1.5,
          straight: false,
        },
        number: {
          density: {
            enable: true, // Enable particle density based on canvas size
          },
          value: 250, // Number of particles
        },
        opacity: {
          value: 0.5, // Set opacity to 50%
        },
        shape: {
          type: "circle", // Circle-shaped particles
        },
        size: {
          value: { min: 1, max: 3 }, // Variable particle size
        },
      },
      detectRetina: true, // Enable retina display support
    }),
    []
  );

  if (init) {
    return <Particles options={options} />;
  }

  return null;
};

export default ParticlesBackground;
