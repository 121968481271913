import React, { FC } from "react";
import { styled } from "styled-components";
import { Section } from "../components/Section";
import ProjectContainer from "../components/Projects/ProjectContainer";
import { projects } from "../components/Projects/ProjectsData";

const ProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const MyProjects = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;
const Projects: FC = () => (
  <Section id="projects" title="Projects">
    <MyProjects>
      <ProjectsGrid>
        {projects.map((project) => (
          <ProjectContainer key={project.id} {...project} />
        ))}
      </ProjectsGrid>
    </MyProjects>
  </Section>
);
export default Projects;
